<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Role Name") }}</div>
    <div class="details">{{ contentFormInfo.name }}</div>
    <el-button @click="handleEdit" class="editBtn">{{ __("Edit") }}</el-button>
    <el-button
      @click="handleDelete(contentFormInfo)"
      style="margin-top: 10px"
      class="deleteBtn"
      >{{ __("Delete") }}
    </el-button>
    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false">
        <div style="max-height: 100vh;">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <page-header
                  style="padding-left: 0"
                  :title="__('Role')"
                  :contentId="id"
                />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Role Name')" prop="name">
                  <el-input
                    v-model="contentForm.name"
                    v-loading="isCheckingName"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="scope" :label="__('Access Scope')">
                  <el-radio-group
                    v-model="contentForm.scope"
                    @change="setScope"
                  >
                    <el-radio label="ac">{{ __("Account") }}</el-radio>
                    <el-radio label="sp">{{ __("Service Provider") }}</el-radio>
                    <el-radio label="system">{{ __("System") }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="permissions.length">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item prop="role_permission">
                    <el-cascader
                      ref="permCascader"
                      style="width: 100%"
                      v-model="contentForm.role_permissions"
                      :key="cascaderKey"
                      :options="permissions"
                      :props="perm_props"
                      clearable
                      filterable
                      v-loading="isFetchingPermissions"
                      @change="permissionChange"
                    ></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item :label="__('Permissions')">
                    <el-input v-loading="isFetchingPermissions"> </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>

          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />

            <el-button @click="closeModal" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { checkUniqueName } from "@/api/role";
import _ from "lodash";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton
  },
  data() {
    let validateRoleName = async (rule, value, callback) => {
      try {
        if (
          this.id === -1 ||
          (this.id === null && this.contentFormInfo.name !== value)
        ) {
          this.isCheckingName = true;
          const res = await checkUniqueName({ name: value });
          this.isCheckingName = false;
          if (res.data.found) {
            callback(new Error(__("Role name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isCheckingName = false;
      }
    };
    let checkSelectedAccessScope = (rule, value, callback) => {
      // If account scope isn't selected
      if (!this.contentForm.scope) {
        callback(__("Access scope is required"));
      } else {
        callback();
      }
    };

    return {
      perm_props: { multiple: true },
      isCheckingName: false,
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: __("Role name is required")
          },
          {
            trigger: "blur",
            validator: validateRoleName
          }
        ],
        scope: [
          {
            required: true,
            trigger: "blur",
            validator: checkSelectedAccessScope
          }
        ]
      },
      defaultProps: {
        children: "children",
        label: "label"
      }
    };
  },

  computed: {
    ...mapState("roles", {
      isLoading: state => state.isLoading
    }),
    ...mapState("permissions", {
      permissions: "permissions",
      isFetchingPermissions: "isLoading"
    }),
    mappedScope() {
      return contentForm => {
        return contentForm.scope;
      };
    },
    cascaderKey() {
      return this.contentFormInfo.id > 0
        ? this.contentFormInfo.id
        : Math.random();
    }
  },

  methods: {
    ...mapActions("roles", {
      createRole: "createRole",
      updateRole: "updateRole",
      deleteContentMethod: "deleteRole"
    }),
    ...mapActions("permissions", {
      getAcScopePermissions: "getAcScopePermissions",
      getPermissions: "getPermissions",
      setEmptyPermission: "setEmptyPermission"
    }),

    permissionChange(value) {
      let checkedNodes = this.$refs.permCascader.getCheckedNodes(true);
      let mandatory_permission = this.mandatory_permission(checkedNodes, value);
      if (
        mandatory_permission !== undefined &&
        mandatory_permission.length > 0
      ) {
        let clone_permission = _.clone(this.contentForm.role_permissions);
        clone_permission.push(mandatory_permission[0]);
        this.contentForm.role_permissions = clone_permission;
      }
    },

    async setScope(change) {
      this.scope = change;
      if (change === "system") {
        await this.getPermissions({ fetch_all: 1, tree: 1 });
      } else {
        await this.getAcScopePermissions(this.scope);
      }
    },

    closeModal() {
      this.setEmptyPermission();
      this.handleCancel();
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process = this.id === -1 ? this.createRole : this.updateRole;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Role created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Role updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.closeModal();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "edit") {
          this.setEmptyPermission();
          await this.setScope(this.mappedScope(this.contentForm));
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
